import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import AppRouter from "@/common/app/router";
import { getOssAssetsGenerator } from "@/common/defines/ali-oss";

const oss = getOssAssetsGenerator("home-page/about-us/");

const HomeSectionAboutUs: React.FC = (props) => {
  return (
    <div className={classNames([styles.section])}>
      <div className={classNames([styles.content, "flex-row flex-main-axis-between"])}>
        <div className={styles.left}>
          <div className={styles.titleEN}>COMPANY PROFILE</div>
          <div className={styles.titleCN}>公司简介</div>
          <div className={styles.text1}>
            专注企业全生命周期服务，打造一站式企业服务平台，公司总部位于“天府之国成都，成立于2020年旗下品牌。
          </div>
          <div className={styles.text2}>
            “汇蚁网”由一支拥有资深企业管理经验和电商服务经验的专业团队打造，凭借电商行业多年的实战经验，致力于从中小卖家的痛点出发，提供对应的解决方案
          </div>
          <div className={classNames([styles.bottom, "flex-row flex-main-axis-between flex-cross-axis-end"])}>
            <img className={styles.logo} src={oss("logo-gray.png")} alt="" />
            <div className={styles.button} onClick={() => window.open(AppRouter.Others.about)}>
              了解更多
            </div>
          </div>
        </div>
        <img className={styles.right} src={oss("huiyiwang.png")} alt="logo" />
      </div>
    </div>
  );
};

export default HomeSectionAboutUs;
